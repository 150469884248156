import {
  Accordion,
  AccordionBody,
  AccordionHeader,
} from '@material-tailwind/react'
import {StoryblokComponent, storyblokEditable} from '@storyblok/react'
import React from 'react'
import {
  CollapsedContentItemStoryblok,
  CollapsedContentStoryblok,
} from 'types/storyblok-types'
import {HeaderBlock, HeaderType} from './Header'

const Icon = ({id, open}: {id: number; open: number | null}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="35"
      viewBox="0 0 24 35"
      fill="none"
      className={`${open === id + 1 && open !== 0 ? 'rotate-180' : ''} `}
    >
      <path
        d="M16.7198 21.373C17.0977 21.7559 17.7114 21.7559 18.0893 21.373L23.8938 15.4913C24.2717 15.1084 24.2717 14.4865 23.8938 14.1036C23.5159 13.7207 22.9022 13.7207 22.5243 14.1036L17.403 19.2929L12.2818 14.1067C11.9039 13.7237 11.2902 13.7237 10.9123 14.1067C10.5344 14.4896 10.5344 15.1114 10.9123 15.4944L16.7168 21.376L16.7198 21.373Z"
        fill="#005E75"
      />
    </svg>
  )
}

const AccordionTemplate = ({
  item,
  index,
}: {
  item: CollapsedContentItemStoryblok
  index: number
}) => {
  const [open, setOpen] = React.useState<null | number>(
    item.expand ? index + 1 : null,
  )
  const handleOpen = (value: number) => setOpen(open === value ? 0 : value)

  return (
    <Accordion
      id={item._uid}
      key={item._uid}
      open={open === index + 1}
      className="rounded-dats border border-dats-s9"
      icon={<Icon id={index} open={open} />}
    >
      <AccordionHeader
        onClick={() => handleOpen(index + 1)}
        className={`border-0 px-5 font-rubik font-medium hover:text-dats-s6 ${
          open === index + 1 ? 'py-2 pb-[25px]' : 'py-2'
        }`}
      >
        <HeaderBlock
          title={item.title}
          withoutBottomMargin
          type={HeaderType.h4}
        />
      </AccordionHeader>
      <AccordionBody className="flex flex-col gap-5 p-5 pt-0 font-body text-base">
        {item.content && item.content.length !== 0
          ? item.content.map(blok => (
              <StoryblokComponent key={blok._uid} blok={blok} />
            ))
          : null}
      </AccordionBody>
    </Accordion>
  )
}

export const CollapsedContentBlock = ({
  blok,
  items,
}: {
  blok: CollapsedContentStoryblok
  items: CollapsedContentItemStoryblok[]
}) => {
  return (
    <div {...storyblokEditable(blok)} className="flex flex-col gap-5">
      {items.map((item, index) => (
        <AccordionTemplate item={item} index={index} key={item._uid} />
      ))}
    </div>
  )
}
const CollapsedContentContainer = ({
  blok,
}: {
  blok: CollapsedContentStoryblok
}) => {
  return <CollapsedContentBlock blok={blok} items={blok.items} />
}

export default CollapsedContentContainer
